import getRole from "@/auth/getRole"
import axios from "axios"
import configs from "./configs"
import {useUserInfoStore} from '../stores/userInfoStore'

export const getMenuLeaf = async (_path?:string, instance?:string) =>{
    const path = _path.replace(/^\//,"").replace(/%/g,"%25")

    try{
        const res = await tFetch(`/api/v3/get-menu?pathEncoded=${encodeURIComponent(path || '/')}&instance=${instance||''}`) 

        if(res.status == 200 && res.payload){
            return {
                ...res.payload,
                display: res.payload.display || res.payload.Display,
                children: (res.payload.children || res.payload.Children||[]).map((c)=>{
                    return {
                        ...c,
                        display: c.display || c.Display
                    }
                })
            }
        }else{
            console.log('getMenuLeaf err 01', res)
            return {
                id: '',
                name: '',
                display: '',
                children:[]
            }
        }
    }catch(e){
        console.log('getMenuLeaf err', e)
        return undefined
    }
}

export const getAllMenuItems = async ():Promise<MenuItem[]> => {
    try{
        const res = await axios.get(configs.urlPrefix + '/site-data/menu')
        console.log('getAllMenuItems', res.data)
        

        if(res.data.code == 0){
            // return res.data
            rootCache.value = res.data.payload
        
            const role = getRole()  
            console.log("role",role)
        
            const out = rootCache.value
            return out
        }else{
            console.log('getMenuRoots err 01', res.data)
            return [] as MenuItem[]
        }
    }catch(e){
        console.log('getMenuRoots err', e)
        return [] as MenuItem[]
    }
}


const rootCache = {value:[] as any}
const rootCacheUser = {value:-1}

const filterByRole = (items:MenuItem[]) => {
    const role = getRole()
    return items.filter(x=>{
        return (x.whitelist_roles||[]).includes(role)
    })
}
export const getMenuRoots = async ():Promise<MenuItem[]> => {

    if(rootCache.value.length > 0){
        try{
            const userInfoStore = useUserInfoStore()
            if(rootCacheUser.value == userInfoStore.info.id){
                return filterByRole(rootCache.value)
            }
        }catch(e){
            console.log("getMenuRoots err", e)
        }
    }

    try{
        const res = await axios.get(configs.urlPrefix + '/site-data/menu')

        if(res.data.code == 0){
            // return res.data
            rootCache.value = res.data.payload

            const out = filterByRole(rootCache.value)
            return out
        }else{
            console.log('getMenuRoots err 01', res.data)
            return [] as MenuItem[]
        }
    }catch(e){
        console.log('getMenuRoots err', e)
        return [] as MenuItem[]
    }
}